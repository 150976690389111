import React from 'react'
import styles from './shopBy.module.scss';
import { convertToModule } from 'factory/utils/clientUtils';
import HK from 'factory/modules/HK';
const index = ({data,title}) => {

  // let data = [1, 2, 3]
  return (
    <div className={convertToModule(styles, 'section_wrapper container')}>
      <div className={convertToModule(styles, 'section_title')}>
        <h2 className={convertToModule(styles, 'collection-list-title')}   >
        {title}
        </h2>
      </div>
      <ul className={convertToModule(styles, 'collection_uls')}>
        {
          data && data.scItms && data.scItms.map((ele, k) => {
            return (
              <li className={convertToModule(styles, 'collection_li')}>
                <a className={convertToModule(styles, 'card_content')} href={ele.imgItm && ele.imgItm.lp}>
                  <div className={convertToModule(styles, 'collection_card')}>
                    <div className={convertToModule(styles, 'collection_card__img')} >
                      <img className={convertToModule(styles, 'customCollectionImg')} src={ele.imgItm && ele.imgItm.imageUrl} />
                    </div>
                    <div className={convertToModule(styles, 'collection_card__text')} >
                      <h3><h2 className={convertToModule(styles, 'bold_text')} ><span>{ele.dis_nm && ele.dis_nm.replace(" Years", "")}</span> &nbsp;Years</h2>  <img  className={convertToModule(styles, 'arrow-card')} src={HK.getImage('/common/shopBy.png')} /></h3>
                    </div>
                  </div>
                </a>
              </li>
            )
          })
        }

      </ul>
    </div>
  )
}

export default index